import { rootApi } from "../../api/rootApi";

export const modenikApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    modenikBulkUploadOrders: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/modenik/add`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    modenikGetAllOrders: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/modenik`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body : params.body
        };
      },
    }),


    revertOrderPoints: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/tropolite/revert/${params.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

   
  }),
});

export const {
 useModenikBulkUploadOrdersMutation , useModenikGetAllOrdersMutation , useRevertOrderPointsMutation
} = modenikApi;
